import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"font-weight-bold display-1 mb-6"},[_c('span',[_vm._v(_vm._s(_vm.$t("settings.title")))])]),_c(VCardText,[_c('div',{staticClass:"settings-item align-start mb-4"},[_c('div',{staticClass:"setting-title"},[_vm._v(_vm._s(_vm.$t("settings.pfp")))]),_c(VSpacer),_c('PfpController')],1),_c('div',{staticClass:"settings-item mb-4"},[_c('div',{staticClass:"setting-title"},[_vm._v(_vm._s(_vm.$t("settings.dark")))]),_c(VSpacer),_c('DarkModeSwitch',{attrs:{"show-label":false}})],1)]),_c(VDivider),_c(VCardTitle,{staticClass:"font-weight-bold display-1 mb-6"},[_vm._v(_vm._s(_vm.$t("subscription.title")))]),(_vm.hasSubscription)?_c(VCardText,[(_vm.subscriptionDetailsLoaded)?_c('div',[_c('ActiveSubscription'),(_vm.subscriptionDetails.latestInvoice)?_c('LatestInvoice',{staticClass:"mt-8"}):_vm._e()],1):_c('div',[_c(VSkeletonLoader,{staticClass:"mx-auto border mb-4",attrs:{"type":"text, table-heading@2","max-height":"170"}}),_c(VSkeletonLoader,{staticClass:"border ml-4 mb-4 w-full",attrs:{"type":"button","max-height":"40","width":"400"}}),_c(VSkeletonLoader,{staticClass:"mx-auto border mb-4",attrs:{"type":"text, table-heading","max-height":"170"}})],1)]):_c(VCardText,[_c('div',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t("subscription.noactivesub")))]),_c(VBtn,{attrs:{"link":"","to":"/subscription/pricing","color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t("subscription.plans")))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }