



























































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import DarkModeSwitch from "./components/DarkModeSwitch.vue";
import PfpController from "./components/PfpController.vue";
import BaselineSessions from "./components/BaselineSessions.vue";
import BaselineAnalysis from "@/core/models/sessions/BaselineAnalysis";
import BaselineSessionUpload from "./components/BaselineSessionUpload.vue";
import ActiveSubscription from "./components/ActiveSubscription.vue";
import { Subscription } from "@/core/models";
import LatestInvoice from "@/views/settings/components/LatestInvoice.vue";

@Component({
  components: {
    LatestInvoice,
    ActiveSubscription,
    PfpController,
    DarkModeSwitch,
    BaselineSessions,
    BaselineSessionUpload,
  },
})
export default class Settings extends Vue {
  @Getter("profile/baseline") baseline!: BaselineAnalysis;
  @Getter("profile/subscriptionDetails") subscriptionDetails!: Subscription;
  @Getter("profile/hasSubscription") hasSubscription!: boolean;
  @Action("profile/getSubscriptionDetails") getSubscriptionDetails!: Function;

  subscriptionDetailsLoaded = false;

  /*
  get hasTrainingPermissions() {
    return this.permissions?.some(x => {
      const [type] = x.type.split(":"); // Split string at ":" and use first part
      return type === "session_training";
    });
  }
  */

  created() {
    setInterval(() => {
      if (
        this.hasSubscription &&
        this.subscriptionDetails &&
        !this.subscriptionDetailsLoaded
      )
        this.getSubscriptionDetails(this.subscriptionDetails.subscriptionId)
          .then(() => {
            this.subscriptionDetailsLoaded = true;
            clearInterval();
          })
          .catch(() => {
            clearInterval();
          });
    }, 800);
  }
}
